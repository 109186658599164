<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
            <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;">试卷管理</a>
          <i>></i>
          <a href="javascript:;">试卷列表</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">试卷编辑</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ formData.paperQuestionId ? '修改题目' : '新增题目'}}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="formData" :inline="true" :model="formData" label-width="100px" size="small" :rules="formDataRules">
              <el-form-item label="题目类型" prop="questionType" class="form-item">
                <el-select
                    v-model="formData.questionType"
                    placeholder="请选择题目类型"
                    :disabled="formData.paperQuestionId"
                >
                  <el-option
                      v-for="item in QuestionTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
<!--              <el-form-item label="知识点" prop="knowledgeId" class="form-item">-->
<!--                <el-cascader-->
<!--                    v-model="formData.knowledgeId"-->
<!--                    :options="knowledgeIdList"-->
<!--                    :props="{-->
<!--                      checkStrictly: true,-->
<!--                      emitPath: false,-->
<!--                      label:'knowledgeName',-->
<!--                      value:'knowledgeId',-->
<!--                      children:'children'-->
<!--                    }"-->
<!--                    placeholder="请选择知识点">-->
<!--                </el-cascader>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="难度" prop="difficultyLevel" class="form-item">-->
<!--                <el-select-->
<!--                    v-model="formData.difficultyLevel"-->
<!--                    placeholder="请选择难度"-->
<!--                >-->
<!--                  <el-option-->
<!--                      v-for="item in DegreeDifficultyList"-->
<!--                      :key="item.value"-->
<!--                      :label="item.label"-->
<!--                      :value="item.value"-->
<!--                  >-->
<!--                  </el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
            </el-form>
            <SingleChoiceQuestions :stu="formData.stu" :formData="formData" v-if="formData.questionType == 10"/>
            <MultipleChoiceQuestions :stu="formData.stu" :formData="formData" v-if="formData.questionType == 20"/>
            <Completion :stu="formData.stu" :formData="formData" v-if="formData.questionType == 40"/>
            <Judge :stu="formData.stu" :formData="formData" v-if="formData.questionType == 30"/>
            <ShortAnswer :stu="formData.stu" :formData="formData" v-if="formData.questionType == 50"/>
            <CalculationQuestions :stu="formData.stu" :formData="formData" v-if="formData.questionType == 60"/>
            <EssayQuestion :stu="formData.stu" :formData="formData" v-if="formData.questionType == 64"/>
            <CaseAnalysisQuestionsReal :stu="formData.stu" :formData="formData" v-if="formData.questionType == 68"/>
            <CombinationQuestion :stu="formData.stu" :formData="formData" v-if="formData.questionType == 80"/>
            <CaseAnalysisQuestions :stu="formData.stu" :formData="formData" v-if="formData.questionType == 70"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 单选题
import SingleChoiceQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/SingleChoiceQuestions";
// 多选题
import MultipleChoiceQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/MultipleChoiceQuestions";
// 填空题
import Completion from "@/views/inkQuestionBank/QuestionBankManage/questionType/Completion";
// 判断题
import Judge from "@/views/inkQuestionBank/QuestionBankManage/questionType/Judge";
// 简答题
import ShortAnswer from "@/views/inkQuestionBank/QuestionBankManage/questionType/ShortAnswer";
// 计算题
import CalculationQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/CalculationQuestions";
// 论述题
import EssayQuestion from "@/views/inkQuestionBank/QuestionBankManage/questionType/EssayQuestion";
// 案例分析题
import CaseAnalysisQuestionsReal from "@/views/inkQuestionBank/QuestionBankManage/questionType/CaseAnalysisQuestionsReal";
// 组合题(单一题型)
import CombinationQuestion from "@/views/inkQuestionBank/QuestionBankManage/questionType/CombinationQuestion";
// 组合题(复合题型)
import CaseAnalysisQuestions from "@/views/inkQuestionBank/QuestionBankManage/questionType/CaseAnalysisQuestions";

export default {
  name: "QuestionAddOrEdit",
  components: {
    SingleChoiceQuestions,
    MultipleChoiceQuestions,
    Completion,
    Judge,
    ShortAnswer,
    CalculationQuestions,
    EssayQuestion,
    CaseAnalysisQuestionsReal,
    CombinationQuestion,
    CaseAnalysisQuestions
  },
  data() {
    return {
      formData: {
        /*题库*/
        stu:'',// stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷

        questionType: '10',
      },
      // 题目类型下拉
      QuestionTypeList: [],
      // 难度下拉
      DegreeDifficultyList: [],
      formDataRules:{
        questionType:[{required: true, message: '请选择题目类型', trigger: 'change'}],
      }

    };
  },
  computed: {},
  created() {

    // stu 1题库  2组合题(单一题型)/组合题(复合题型)  3试卷
    this.formData.stu = this.$route.query.stu

    if(this.formData.stu == '3'){


      this.formData.paperId = this.$route.query.paperId
      this.formData.partitionId = this.$route.query.partitionId||undefined
      this.formData.paperQuestionId = this.$route.query.paperQuestionId||undefined

      // this.formData.questionNameStr = this.$route.query.questionNameStr
      // this.formData.questionTypeStr = this.$route.query.questionTypeStr



    }

    this.getQuestionTypeList()
  },
  methods: {
    // 去除空数据
    delNullData(list){
      list.forEach((e,i)=>{
        if(e.children.length == 0){
          e.children = undefined
        }else{
          this.delNullData(e.children)
        }
      })
      return list
    },
    //获取测评分类标识--码值
    getQuestionTypeList() {
      const QuestionType = this.$setDictionary("TOOL_QUESTION_TYPE", "list");

      for (const key in QuestionType) {
        this.QuestionTypeList.push({
          value: key,
          label: QuestionType[key],
        });
      }
    },

  }
}
</script>

<style lang="less" scoped>

</style>